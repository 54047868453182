import React, { createContext, useState } from "react"

// set default values for context
const defaultValues = {
  isNavOpen: false,
  toggleNavOpen: () => {},
  setNavOpen: () => console.log("SetNavOpen"),
}

export const ArtSciContext = createContext(defaultValues)

export const ArtSciProvider = ({ children }) => {
  // set the initial state
  const [isNavOpen, setNavOpen] = useState(false)

  const toggleNavOpen = () => setNavOpen(!isNavOpen)

  return (
    <ArtSciContext.Provider
      value={{ ...defaultValues, isNavOpen, toggleNavOpen, setNavOpen }}
    >
      {children}
    </ArtSciContext.Provider>
  )
}

// --- END OF PROVIDER ---- //
