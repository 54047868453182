import React from "react"

import { ArtSciProvider } from "./src/context/ArtSciContext"
import "./src/styles/tailwind.css"

// Wrap root element in ArtSci Provider

export const wrapRootElement = ({ element }) => (
  <ArtSciProvider>{element}</ArtSciProvider>
)
