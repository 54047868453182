module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"art-science-physio","short_name":"Art + Science","start_url":"/","background_color":"rgb(249, 250, 251)","theme_color":"rgb(78, 103, 119)","display":"minimal-ui","icon":"src/images/logos/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"40cf7ac97e99498b0ba259afdc43ae45"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
